@use '../variables' as *;

.navbar {
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 74px;
}

.navbarBottomBar {
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: $brand-color;
  position: absolute
}

.headerInner {
  @include page-width();
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  position: relative;
  height: 100%;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }
}

.headerLogoBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $brand-color;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: block;
    width: 180px;
    height: 60px;
    padding: 16px;
  }

  .headerLogoBarInner {
    @include page-width();

    margin-left: 16px;
    top: 4px;
    position: relative;
  }
}

.logo {
  height: 40px;
}

.logoZone {
  width: 210px;
  height: 100px;
  color: transparent;
  font-size: 0.1px;
}

.buttons {
  display: none;

  @include when-big() {
    display: flex;
  }

  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: calc(100% - 210px);

  & > div {
    display: flex;
  }
}

.button {
  overflow: hidden;
  padding: 13px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  position: relative;
  margin-left: 10px;    
  background-color: transparent;
  color: #FFF;

  .buttonIcon {
    margin-right: 10px;
  }

  .buttonCircle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .buttonStroke {
    fill: none;
    stroke: $brand-color;
  }

  .buttonContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
  }
}



.signUpMenu {
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: #FFF;

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 10px 0;
    margin: 0;
    background: #353e5c;
    box-shadow: 0px 0px 60px rgba(#000, 0.3);
    color: #FFF;
    border: 1px solid rgba(255, 255, 255, 0.6);

    li {
      display: block;
      padding: 5px 40px;
      font-weight: bold;

      a {
        cursor: pointer;
      }

      &:not(:last-child):after {
        display: block;
        content: '';
        width: 90px;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.3);
        margin: 10px auto 0;
      }
    }
  }
}

.menu {
  // background-color: #FFF;
  // box-shadow: 0px 0px 60px rgba(#000, 0.3);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 8px;
  height: 550px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.menuOverlay {
  position: fixed;
  top: 73px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

a.menuButton {
  display: flex;
  flex-direction: row;
  color: #FFF;
  background: #225f78;
  width: 330px;
  padding: 20px;
  align-items: flex-start;
  margin: 8px 12px;
  transform: translate3d(-100px, 0, 0);
  opacity: 0;
  transition: all 0.6s ease;
  visibility: hidden;
  border-radius: 16px;
  box-shadow: 0px 0px 60px rgba(#3b3b3b, 0.3);

  &.menuButtonOpen {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: all 0.6s ease-out;
    opacity: 1;
  }

  .menuIcon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    margin-top: 4px;
  }

  .menuText {
    flex-grow: 1;
    text-align: left;
    display: flex;
    flex-direction: column;

    .menuTextTitle {
      font-weight: bold;
      margin-bottom: 4px;
      font-size: 1.2em;
    }

    .menuTextSubtitle {
      font-size: 0.8em;
      letter-spacing: 1px;
    }
  }
}

.mobileMenuButton {
  background: transparent;
  border: none;
  color: #FFF;
  position: absolute;
  top: 20px;
  right: 10px;

  @include when-big() {
    display: none;
  }
}

.mobileMenu {
  display: flex;
  @include when-big() {
    display: none;
  }

  position: fixed;
  top: 0; left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.9);
  z-index: 1001;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    opacity: 1 !important;
  }
}

.mobileMenuItem {
  background: transparent;
  border: none;
  font-family: inherit;
  font-size: 1.1em !important;
  padding: 16px;
  color: #FFF;
}

.mobileMenuClose {
  position: absolute;
  top: 20px;
  right: 10px;
  background: transparent;
  border: none;
  color: #FFF;
  font-size: 1.5em;
}