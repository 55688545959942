@use '../../variables' as *;

.container {
  font-size: 1.3em;
  margin: 10px;
  background: #ffffff;
  color: #000;
  text-align: left;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  width: 180px;
  height: 180px;
  font-weight: 500;

  @include when-big() {
    width: 500px;
    height: 230px;
  }

  h3 {
    margin: 0 0 6px 0;
    max-width: 100%;
    font-size: 1em;
  }

  p {
    font-size: 0.7em;
  }
}

.video {
  display: block;
  object-fit: cover;
  filter: blur(1px);
  @include absolute-fill;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  @include absolute-fill;
}

.content {
  padding: 20px;
  @include absolute-fill;
  color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;

  p {
    display: none;
    @include when-big() {
      display: block;
    }
  }
}