.container {
  background: #FFF;
  padding: 20px;
}

.table {
  margin-top: 50px;

  td:first-child {
    text-align: left;
    width: 300px;
    font-size: 0.8em;
  }

  td:not(:first-child) {
    text-align: center;
    width: 250px;
  }
}

.basePrice {
  td {
    font-size: 1.3em !important;
  }
  font-weight: bold;
  margin-bottom: 20px;
}

.paramSelection {
  margin: 16px;
}

.selectButton {
  background-color: #efefef;
  border: none;
  font-family: inherit;
  font-size: inherit;
  padding: 10px 16px;
  width: 160px;

  &:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  &:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

.selectButtonActive {
  background-color: rgb(58, 115, 184);
  color: #FFF;
}