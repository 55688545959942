@use "../../variables" as *;

.container {
  position: relative;

  @include when-big() {
    height: 650px;
  }
}

.containerLayer1 {
  background: linear-gradient(90deg, #225f78 0%, #7529b8 100%);
  color: #FFF;
  height: 100%;
  width: 100%;

  @include when-big() {
    position: absolute;
    clip-path: polygon(0 0, 0 100%, 100% 70%, 84% 100%, 100% 0);
  }
}

.containerLayer0 {
  position: absolute;
  height: 100%;
  width: 100%;
  display: none;

  @include when-big() {
    display: block;
    background: none;
    color: #235f79;
  }
}

.containerWidth {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 160px;
  padding-bottom: 80px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;

  @include when-big() {
    width: 1300px;
    padding-bottom: 0;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1.title {
  margin: 0;
  margin-bottom: 1.4em;
  font-size: 3em;
  line-height: 1.1em;

  @include when-big() {
    font-size: 3.8em;
  }
}

.subtitle {
  font-size: 1.4em;
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 1.1em;

  @include when-big() {
    margin-bottom: 0;
    font-size: 1.3em;
  }
}

.tips > div {
  align-items: center;
  font-weight: bold;
  margin: 0.8em 0.3em;

  svg {
    position: relative;
    top: 2px;
  }
}