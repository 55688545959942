@use "../../variables" as *;

@keyframes gradient {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotatingGradientContainer {
  position: relative;
}

.rotatingGradient {
  animation: gradient 15s linear infinite;
  position: absolute;
  left: -80px;
  top: -80px;
}

.hero {
  background-image: url("../../assets/bg-top.jpg");
  background-size: cover;
  color: #fff;
  position: relative;

  background-position: center;
  @include when-big() {
    background-position: right;
  }

  &:after {
    @include till-big {
      content: "";
      display: block;
      background: rgba(#000, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.container {
  background-color: #000;
  font-size: 20px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  @include when-big() {
    font-size: 30px;
    text-shadow: none;
  }

  h1 {
    font-size: 2.5em;
    line-height: 1.2;
  }

  h2 {
    font-size: 1.2em;
    line-height: 1.2;
    font-weight: 300;
    font-weight: bold;
  }
}

.bigLogo {
  height: 100px;
  margin-left: 6px;
}

.heroContentTexts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @include when-big() {
    width: 600px;
    align-items: flex-start;
    text-align: left;
  }
}

.autotype {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  font-weight: bold;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 16px;
  box-sizing: border-box;

  h1 {
    font-family: "Cousine";
    height: 230px;

    @include when-big() {
      height: 160px;
    }
  }

  width: 100%;
  @include when-big() {
    width: 50% !important;
  }
}
