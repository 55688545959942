@use "variables" as *;

.container {
  background: rgb(255, 255, 255);
  color: #000000;
  position: fixed;
  bottom: 40px;
  right: 0;
  left: 0;
  margin: 0 auto;
  padding: 16px;
  border-radius: 16px;
  border: 3px solid rgba(57, 57, 57, 0.4);
  font-size: 0.8em;
  width: calc(100% - 32px);
  box-sizing: border-box;
  z-index: 99999;

  @include when-big() {
    font-size: 1em;
    max-width: 500px;
    min-width: 450px;
  }

  h2 {
    font-size: 1em;
    margin: 0;
  }

  p {
    font-size: 0.9em;
  }

  a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid rgba(255,255,255, 0.5);
  }
}

.consent {
  background: transparent;
  border: 1px solid #515151;
  color: #000;
  padding: 8px;
  font-family: inherit;
  font-weight: bold;
}

.close {
  background: transparent;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 1.5em;
  position: absolute;
  right: 10px;
  top: 10px;
}