@use '../../variables' as *;

.container {
  background-color: #FFF;
  padding-top: 60px;

  &:before {
    display: block;
    content: "";
    transform: scaleY(-1);
    background-image: linear-gradient(180deg, rgba(254, 254, 254, 0.00) 0%, rgba(0, 0, 0, 0.25) 100%);
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.inner {
  display: block;
  text-align: left;
}

.descContainer {
  display: block;
  padding-top: 20px;
  margin: 0 auto;
  padding: 16px;
  box-sizing: border-box;

  h2 {
    @include when-xs() {
      font-size: 1.2em;
    }
  }

  .section {
    display: flex;
    align-items: flex-start;
    line-height: 1.8;
    margin: 50px 0;

    flex-direction: column-reverse;

    @include when-big() {
      flex-direction: row;
      align-items: center;
    }
  }

  .description {
    font-size: 0.9em;
    @include when-big() {
      font-size: 1em;
    }
    @include when-xs {
      font-size: 0.8em;
    }
  }

  .illustration {
    display: flex;
    flex-direction: row-reverse;
    @include when-big() {
      min-width: 40%;
      margin: 10px;
    }

    img {
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
      max-width: 40vw;
      max-height: 40vh;

      @include when-big() {
        max-width: 80%;
        max-height: 70vh;
      }
    }

    &Left {
      margin-top: 40px;
      @include when-big() {
        margin-top: 0;
      }
      flex-direction: row;
    }
  }

  .tips {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    flex-wrap: wrap;

    @include when-big {
      flex-direction: row;
    }
  }
}

.ctaRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 40px;
}

.cta {
  display: block;
  background: linear-gradient(90deg, #225f78 0%, #7529b8 100%);
  color: #FFF;
  padding: 20px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.4em;
}

.hideWhenSmall {
  display: none;

  @include when-big() {
    display: block;
  }
}