

@keyframes sliding-stars {
  0% {
    transform: translate3d(90%, 90%, 0) scale(0.2);
    opacity: 0;
  }
  25% {
    transform: translate3d(90%, 90%, 0), scale(0.2);
    opacity: 0.4;
  }
  50% {
    transform: translate3d(35%, 35%, 0) scale(0.8);
    opacity: 1;
  }
  75% {
    transform: translate3d(-10%, -10%, 0) scale(0.2);
    opacity: 0.4;
  }
  100% {
    transform: translate3d(-10%, -10%, 0) scale(0.2);
    opacity: 0;
  }
}

.aiStarsAnimation {
  width: 150px;
  height: 150px;
  position: relative;
  margin-top: 30px;
  margin-bottom: 60px;

  svg {
    display: block;
    position: absolute;  
    animation-name: sliding-stars;
    animation-duration: 4s;
    animation-timing-function: ease-in;  
    animation-iteration-count: infinite;
    width: 100%;
    height: 100%;
    top: -35px;
    left: -35px;
  }

  svg:nth-child(1) {
    animation-delay: -1s;
  }
  svg:nth-child(2) {
    animation-delay: -2s;
  }
  svg:nth-child(3) {
    animation-delay: -3s;
  }
  svg:nth-child(4) {
    animation-delay: -4s;
  }
  // svg:nth-child(5) {
  //   animation: sliding-stars-5 4s linear infinite;
  // }

}