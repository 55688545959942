@use '../../variables' as *;

.container {
  background-color: #FFF;
  padding-top: 60px;

  .containerAnimatedContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }
}

.containerOverlay {
  background: radial-gradient(circle, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.20) 100%);
}

.inner {
  display: block;
  text-align: left;
}

.topPart {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 20px;
  align-items: center;
  
  .topPartContent {
    font-size: 3em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    position: absolute;
    bottom: 0;
    margin: 0px auto;
    width: 100%;
  }
}

.sidePart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .sidePartBg {
    background: linear-gradient(90deg, #0f0f0f, #595959);
    color: #a49090;
    padding: 20px;
    justify-content: center;
    align-items: center;
    display: flex;

    border-radius: 50%;

    width: 80px;
    height: 80px;
    @include when-big() { 
      width: 100px;
      height: 100px;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }


}

.bottomPart {
  display: flex;
  flex-grow: 1;
  position: relative;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;

  .bottomPartContent {
    height: 120px;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: absolute;
    top: 0;
    margin: 0px auto;
    width: 100%;
  }
}

.profitRow {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: center;
  position: relative;
}