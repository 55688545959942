@use '../../variables' as *;

.container {
  background-color: #FFF;
  padding-top: 60px;

  &:before {
    display: block;
    content: "";
    transform: scaleY(-1);
    background-image: linear-gradient(180deg, rgba(254, 254, 254, 0.00) 0%, rgba(0, 0, 0, 0.25) 100%);
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.inner {
  display: block;
  text-align: left;
}

.descContainer {
  text-align: center;
  color: #FFF;
  line-height: 1.8;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  font-size: 0.9em;

  @include when-big() {
    margin-top: 0;
    font-size: 1em;
  }
}