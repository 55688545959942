.container {
  position: relative;
  margin-right: 10px;
  margin-bottom: 8px;
}

.arrow {
  position: absolute;
  top: 0;
  left: 0;
  width: 0; 
  height: 0; 
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent; 
  border-right: 18px solid var(--bg-color);
}

.content {
  margin-left: 17px;
  background-color: var(--bg-color);
  height: 32px;
  color: var(--fg-color);
  display: flex;
  align-items: center;
  padding-left: 6px;
  padding-right: 16px;
  font-weight: bold;
}