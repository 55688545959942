@use "../variables" as *;

.section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  line-height: 1.8;
  margin: 0 auto;
  margin-top: 100px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  text-align: justify;

  @include when-big() {
    text-align: left;
    padding: 0;
  }

  @include when-big() {
    width: 1300px;
  }

  h2 {
    @include till-big() {
      text-align: center;
    }
  }
}

.sectionContent {
  box-sizing: border-box;
}

.heading {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 15px 0;
  }
}

.pre-heading {
  font-size: 1em;
  letter-spacing: 5px;
  text-transform: uppercase;
}

.tips {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  width: 100%;

  @include when-big() {
    flex-direction: row;
    width: auto;
  }
}

.contactAppendix {
  background: linear-gradient(90deg, #225f78 0%, #7529b8 100%);
  color: #fff;
  padding: 40px 20px;
  margin-top: 60px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  a {
    color: #fff;
  }

  h2 {
    margin-top: 0;
  }
}

a.contactAppendixCta {
  background-color: #fff;
  color: #481ab2;
  padding: 20px;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
}

.benefit {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 20px;
  @include when-big() {
    padding: 0;
  }

  text-align: center;
  @include when-big() {
    text-align: right;
  }

  @include when-big() {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  & > div:first-child {
    @include when-big() {
      width: 50%;
    }
  }

  & > div:last-child {
    @include when-big() {
      width: 50%;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 200px;
    height: 200px;
    border-radius: 1000px;
    margin: 20px;
    @include when-big() {
      width: 400px;
      height: 400px;
      border-radius: 200px;
    }

    object-fit: cover;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  }
}

.benefitRev {
  @include when-big() {
    flex-direction: row-reverse;
    text-align: left;
  }
}

.embeddedImg {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  width: 100%;

  img {
    max-width: 80%;

    @include when-big() {
      max-width: 400px;
    }
  }
}

.posContainer {
  background-color: #FFF;
  padding: 10px 0;
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.biggerEmbeddedImg {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  width: 100%;

  img {
    max-width: 80%;

    @include when-big() {
      max-width: 1000px;
    }
  }
}

.centered {
  text-align: center;
}
